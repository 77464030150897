import React, { useEffect, useState } from 'react';
import AuthHeader from '../../layout/AuthHeader';
import * as Yup from "yup";
import { useFormik } from 'formik';
import callApi from '../../services/api/apiClient';
import { dispatchStore } from '../../lib/dispatchStore';
import { AccessTokenSlice } from '../../redux/reducers/reducer';
import AlertPopup from '../../components/AlertPopUp/AlertPopUp';
import { selectStore } from '../../lib/selectStore';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EditAccountDetailSchema = Yup.object().shape({
  name: Yup.string(),
  phone: Yup.string()
});


export default function EditAccountDetail() {
  const navigate = useNavigate()

  const [serverError, setServerError] = useState(null);
  const profileDataSelector = useSelector(state => state?.profile?.profile?.data?.data);
  const AccessToken = useSelector(state => state.AccessToken);

  const [changeLanguage, setChangeLanguage ] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EditAccountDetailSchema,
    onSubmit: (values, actions) => {
      const profileDataStore = selectStore("profile")?.profile?.data?.data;
      callApi("profile/update")
        .withBody({
          name: values.name ? values.name : profileDataStore?.name,
          phone: values.phone ? values.phone : profileDataStore?.phone,
        })
        .execute()
        .then((res) => {
          console.log(res)
          if (res?.status_code == 200) {
            callApi("profile/profile")
              .executeDispatch()
            actions.resetForm();
            navigate("/account-detail");
          }
          else {
            setServerError(res?.message)
          }
        })
        .catch((err) => console.log("error", err));
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile")
        .executeDispatch()
    }
  }, [AccessToken]);

  const handleClose = () => {
    setServerError(null)
  };


  return (
    <>
      {
        serverError &&
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={handleClose}
        />
      }

      <AuthHeader title={getLanguageFile.edit_account_detail} backkey={true} />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>
        <form action="" onSubmit={formik.handleSubmit} className="max-w-md mx-auto p-4">

          {/* User Name */}
          <div className="mb-4">
            <label className="text-500 fs-12 font-medium">{getLanguageFile.username}
              <span className="text-red-500">*</span>
              {formik?.errors.name && (
                <span className="text-red-500">
                  {formik.errors.name}
                </span>
              )}
            </label>
            <input
              id="name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name ? formik.values.name : profileDataSelector?.name}

              type="text"
              className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
              placeholder={getLanguageFile.type_username} />
          </div>

          {/* Phone Number */}
          <div className="mb-4">
            <label className="text-500 fs-12 font-medium">{getLanguageFile.phone_number}
              <span className="text-red-500">*</span>
              {formik?.errors.phone && (
                <span className="text-red-500">
                  {formik.errors.phone}
                </span>
              )}
            </label>
            <input
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone ? formik.values.phone : profileDataSelector?.phone}

              type="text"
              className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
              placeholder={getLanguageFile.type_phone_number} />
          </div>

          {/* Save Button */}
          <button
            type='submit'
            className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full py-3 px-8 w-full mt-6 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {getLanguageFile.save}
          </button>
        </form>
      </div>
    </>
  );
}
