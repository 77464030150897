// BuyTicketModal.jsx
import React, { useEffect, useState } from "react";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import callApi from "../../services/api/apiClient";
import mm from '../../assets/images/flags/mm.svg';
import en from '../../assets/images/flags/en.svg';
import ch from '../../assets/images/flags/ch.svg';
const LanguageModal = ({ show, onClose, changeLanguageClick = null, getLanguageFile = []}) => {
  const [isDefaultUs, setIsDefaultUs] = useState(true); 
  const [currentLanguage, setCurrentLanguage] = useState(window.localStorage.currentLanguage || 'en');

  const changeLanguage = (language) => {
    changeLanguageClick(language);
    setIsDefaultUs(!isDefaultUs);
    window.localStorage.setItem("currentLanguage", language);

    
  };

  const flagCircleLayout = {
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "50%"
  }
  if (!show) return null;
  return (
    <div 
      style={{zIndex: "1111111"}}
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg relative w-[90%] max-w-[400px]">
        <button onClick={onClose} className="absolute top-2 right-2">
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <h2 className="fs-14 font-bold mb-4">Select Language</h2>

        <button
          onClick={() => changeLanguage("en")}
          className="flex justify-between items-center py-2 mb-2"
        >
          <div className="flex items-center">
            {/* <svg
              className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#B22234" />
              <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <circle cx="10" cy="10" r="10" fill="white" />
              </mask>
              <g mask="url(#mask0)">
                <path d="M0 2H20V4H0V2Z" fill="white" />
                <path d="M0 6H20V8H0V6Z" fill="white" />
                <path d="M0 10H20V12H0V10Z" fill="white" />
                <path d="M0 14H20V16H0V14Z" fill="white" />
                <path d="M0 18H20V20H0V18Z" fill="white" />
                <path d="M0 0H20V2H0V0Z" fill="#B22234" />
                <path d="M0 4H20V6H0V4Z" fill="#B22234" />
                <path d="M0 8H20V10H0V8Z" fill="#B22234" />
                <path d="M0 12H20V14H0V12Z" fill="#B22234" />
                <path d="M0 16H20V18H0V16Z" fill="#B22234" />
                <path d="M0 0H10V10H0V0Z" fill="#3C3B6E" />
                <path
                  d="M1 1.5L1.30902 2.40451H2.23607L1.46353 2.84549L1.77254 3.75L1 3.30902L0.227458 3.75L0.536471 2.84549L-0.236068 2.40451H0.690983L1 1.5Z"
                  fill="white"
                />
                <path
                  d="M3 1.5L3.30902 2.40451H4.23607L3.46353 2.84549L3.77254 3.75L3 3.30902L2.22746 3.75L2.53647 2.84549L1.76393 2.40451H2.69098L3 1.5Z"
                  fill="white"
                />
                <path
                  d="M5 1.5L5.30902 2.40451H6.23607L5.46353 2.84549L5.77254 3.75L5 3.30902L4.22746 3.75L4.53647 2.84549L3.76393 2.40451H4.69098L5 1.5Z"
                  fill="white"
                />
                <path
                  d="M7 1.5L7.30902 2.40451H8.23607L7.46353 2.84549L7.77254 3.75L7 3.30902L6.22746 3.75L6.53647 2.84549L5.76393 2.40451H6.69098L7 1.5Z"
                  fill="white"
                />
                <path
                  d="M9 1.5L9.30902 2.40451H10.2361L9.46353 2.84549L9.77254 3.75L9 3.30902L8.22746 3.75L8.53647 2.84549L7.76393 2.40451H8.69098L9 1.5Z"
                  fill="white"
                />
                <path
                  d="M1 4.5L1.30902 5.40451H2.23607L1.46353 5.84549L1.77254 6.75L1 6.30902L0.227458 6.75L0.536471 5.84549L-0.236068 5.40451H0.690983L1 4.5Z"
                  fill="white"
                />
                <path
                  d="M3 4.5L3.30902 5.40451H4.23607L3.46353 5.84549L3.77254 6.75L3 6.30902L2.22746 6.75L2.53647 5.84549L1.76393 5.40451H2.69098L3 4.5Z"
                  fill="white"
                />
                <path
                  d="M5 4.5L5.30902 5.40451H6.23607L5.46353 5.84549L5.77254 6.75L5 6.30902L4.22746 6.75L4.53647 5.84549L3.76393 5.40451H4.69098L5 4.5Z"
                  fill="white"
                />
                <path
                  d="M7 4.5L7.30902 5.40451H8.23607L7.46353 5.84549L7.77254 6.75L7 6.30902L6.22746 6.75L6.53647 5.84549L5.76393 5.40451H6.69098L7 4.5Z"
                  fill="white"
                />
                <path
                  d="M9 4.5L9.30902 5.40451H10.2361L9.46353 5.84549L9.77254 6.75L9 6.30902L8.22746 6.75L8.53647 5.84549L7.76393 5.40451H8.69098L9 4.5Z"
                  fill="white"
                />
                <path
                  d="M1 7.5L1.30902 8.40451H2.23607L1.46353 8.84549L1.77254 9.75L1 9.30902L0.227458 9.75L0.536471 8.84549L-0.236068 8.40451H0.690983L1 7.5Z"
                  fill="white"
                />
                <path
                  d="M3 7.5L3.30902 8.40451H4.23607L3.46353 8.84549L3.77254 9.75L3 9.30902L2.22746 9.75L2.53647 8.84549L1.76393 8.40451H2.69098L3 7.5Z"
                  fill="white"
                />
                <path
                  d="M5 7.5L5.30902 8.40451H6.23607L5.46353 8.84549L5.77254 9.75L5 9.30902L4.22746 9.75L4.53647 8.84549L3.76393 8.40451H4.69098L5 7.5Z"
                  fill="white"
                />
                <path
                  d="M7 7.5L7.30902 8.40451H8.23607L7.46353 8.84549L7.77254 9.75L7 9.30902L6.22746 9.75L6.53647 8.84549L5.76393 8.40451H6.69098L7 7.5Z"
                  fill="white"
                />
                <path
                  d="M9 7.5L9.30902 8.40451H10.2361L9.46353 8.84549L9.77254 9.75L9 9.30902L8.22746 9.75L8.53647 8.84549L7.76393 8.40451H8.69098L9 7.5Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg> */}
            <img 
            className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
            src={en}
            style={flagCircleLayout}/>
            <span className="text-300 fs-14 font-normal">English</span>
          </div>
        </button>

        <button
          onClick={() => changeLanguage("mm")}
          className="flex justify-between items-center py-2 mb-2"
        >
          <div className="flex items-center">
            {/* <svg
              className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_322_5657)">
                <path d="M0 0H20V20H0V0Z" fill="#FECB00" />
                <path d="M0 6.66675H20V20.0001H0V6.66675Z" fill="#34B233" />
                <path d="M0 13.3333H20V19.9999H0V13.3333Z" fill="#EA2839" />
                <path
                  d="M6.2196 15.5047L7.96652 8.86439L12.2463 11.8549L6.2196 15.5047Z"
                  fill="white"
                />
                <path
                  d="M3.81737 8.39435L10.9237 7.94023L9.289 12.779L3.81737 8.39435Z"
                  fill="white"
                />
                <path
                  d="M10.1064 4L12.7514 10.3597H7.4613L10.1064 4Z"
                  fill="white"
                />
                <path
                  d="M16.3954 8.39447L10.9237 12.7791L9.28901 7.94035L16.3954 8.39447Z"
                  fill="white"
                />
                <path
                  d="M13.9932 15.5048L7.96645 11.8549L12.2462 8.86442L13.9932 15.5048Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_322_5657">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg> */}
            <img 
            className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
            src={mm}
            style={flagCircleLayout}/>
            <span className="text-300 fs-14 font-normal">Myanmar</span>
          </div>
        </button>

        <button          
          onClick={() => changeLanguage("ch")}
          className="flex justify-between items-center py-2 mb-2"
        >
          <div className="flex items-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" 
                className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
            

              <g clipPath="url(#clip0_3122_5657)">
              <rect x="1" y="4" width="20" height="24" rx="4" ry="4" fill="#db362f"></rect>
              <path d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z" opacity=".15"></path>
              <path fill="#ff0" d="M7.958 10.152L7.19 7.786 6.421 10.152 3.934 10.152 5.946 11.614 5.177 13.979 7.19 12.517 9.202 13.979 8.433 11.614 10.446 10.152 7.958 10.152z"></path>
              <path fill="#ff0" d="M12.725 8.187L13.152 8.898 13.224 8.072 14.032 7.886 13.269 7.562 13.342 6.736 12.798 7.361 12.035 7.037 12.461 7.748 11.917 8.373 12.725 8.187z"></path>
              <path fill="#ff0" d="M14.865 10.372L14.982 11.193 15.37 10.46 16.187 10.602 15.61 10.007 15.997 9.274 15.253 9.639 14.675 9.044 14.793 9.865 14.048 10.23 14.865 10.372z"></path>
              <path fill="#ff0" d="M15.597 13.612L16.25 13.101 15.421 13.13 15.137 12.352 14.909 13.149 14.081 13.179 14.769 13.642 14.541 14.439 15.194 13.928 15.881 14.391 15.597 13.612z"></path>
              <path fill="#ff0" d="M13.26 15.535L13.298 14.707 12.78 15.354 12.005 15.062 12.46 15.754 11.942 16.402 12.742 16.182 13.198 16.875 13.236 16.047 14.036 15.827 13.26 15.535z"></path>
              <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z" fill="#fff" opacity=".2"></path>
              </g>
              <defs>
                <clipPath id="clip0_3122_5657">
                  <rect width="20" height="20" fill="#db362f" />
                </clipPath>
              </defs>
            </svg> */}
            <img 
            className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
            src={ch}
            style={flagCircleLayout}/>
            <span className="text-300 fs-14 font-normal">Chinese</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default LanguageModal;
