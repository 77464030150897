import { res_modifiers_config } from "../../helper/res_modifiers_config";
// import dotenv from "dotenv"

// dotenv.config()
// Define the API URLs for different environments
export const API_URLs = {
  production: process.env.REACT_APP_ENDPOINT,
  v1_development: process.env.REACT_APP_ENDPOINT,
  development: process.env.REACT_APP_ENDPOINT,
};

export const endpoints = Object.freeze({
  auth: {
    signup: {
      method: "post",
      endpoint: "api/user/register",
    },
    signin: {
      method: "post",
      endpoint: "api/user/login",
    },
    createprofile: {
      method: "post",
      endpoint: "api/user/create-profile",
      token: "require",
    },
    resetpassword: {
      method: "patch",
      endpoint: "api/user/update-password",
      token: "require",
    },

  },
  home: {
    homeproduct:{
      method: "get",
      endpoint: "api/products/ticket?show=home&limit=10",
    },
    homelatest:{
      method: "get",
      endpoint: "api/products/ticket?show=latest",
    },
    sliders: {
      method: "get",
      endpoint: "api/sliders",
    },
    categories: {
      method: "get",
      endpoint: "api/categories",
    },
  },
  profile: {
    profile: {
      method: "get",
      endpoint: "api/user/profile",
      token: "require",
    },
    update: {
      method: "put",
      endpoint: "api/user/update-profile",
      token: "require",
    },
    updateShipping: {
      method: "put",
      endpoint: "api/user/update-shipping",
      token: "require",
    },
    uploadImage: {
      method: "post",
      endpoint: "api/user/upload-profile-image",
      token: "require",
    },
  },
  cart: {
    add: {
      method: "post",
      endpoint: "api/user/cart/add",
      token: "require",
    },
    list: {
      method: "get",
      endpoint: "api/user/cart",
      token: "require",
    },
    removeAll: {
      method: "delete",
      endpoint: "api/user/cart/remove-all",
      token: "require",
    },
    removeSelected: {
      method: "delete",
      endpoint: "api/user/cart/remove",
      token: "require",
    },
    updateCart: {
      method: "put",
      endpoint: "api/user/cart/update",
      token: "require",
    },
    paymentlist: {
      method: "get",
      endpoint: "api/payments",
    },
    paymentdetail: {
      method: "get",
      endpoint: "api/payments/{:paymentID}",
    },
    checkout: {
      method: "post",
      endpoint: "api/user/checkout",
      token: "require",
    },
    buynow: {
      method: "post",
      endpoint: "api/user/buynow",
      token: "require",
    },
    buyticket: {
      method: "post",
      endpoint: "api/user/luckydraw/buy-ticket",
      token: "require",
    },
  },
  products: {
    productsByCategory: {
      method: "get",
      endpoint: "api/categories/{:categoryid}/products",
    },
    search: {
      method: "get",
      endpoint: "api/products?search={:search}",
    },
    productDetail: {
      method: "get",
      endpoint: "api/products/{:productSlug}",
    },
    ticket: {
      method: "get",
      endpoint: "api/products/ticket?limit={:perpage}&page={:page}",
    },
    token: {
      method: "get",
      endpoint: "api/products/token?limit={:perpage}&page={:page}",
    },
  },
  luckydraw:{
    myPrices: {
      method: "get",
      endpoint: "api/user/luckydraw/my-prizes",
      token: "require",
    },
    clainPrices: {
      method: "post",
      endpoint: "api/user/claim-prize",
      token: "require"
    },
    detail:{
        method: "get",
        endpoint: "api/user/luckydraw/histories/{:luckydrawid}",
        token: "require",
    },
    live: {
      method: "get",
      endpoint: "api/user/live-lucky-number/{:luckydrawid}",
      token: "require",
    }
  },
  history: {
    order:{
      method: "get",
      endpoint: "api/user/orders",
      token: "require",
    },
    token: {
      method: "get",
      endpoint: "api/user/token-histories",
      token: "require",
    },
    luckydraw: {
      method: "get",
      endpoint: "api/user/luckydraw/histories",
      token: "require",
    },
    shippingOrders: {
      method: "get",
      endpoint: "api/user/shipping-orders",
      token: "require",
    },
  },
  shipping:{
    shipOrder: {
      method: "post",
      endpoint: "api/user/shipping-orders",
      token: "require",
    },
    shipPrice: {
      method: "post",
      endpoint: "api/user/calculate-shipping-price",
      token: "require",
    },
  },
  others: {
    setting:{
      method: "get",
      endpoint: "api/settings",
    },
    onboarding: {
      method: "get",
      endpoint: "api/onboarding",
    },
    regions: {
      method: "get",
      endpoint: "api/regions",
    },
    townships: {
      method: "get",
      endpoint: "api/regions/{:regionid}/townships",
    },
    winnerList: {
      method: "get",
      endpoint: "api/luckydraw-winners",
    },
    winnerDetail: {
      method: "get",
      endpoint: "api/luckydraw-winners/{:luckydrawid}",
      token: "require",
    },
    otherIn: {
      method: "get",
      endpoint: "api/auth/token",
    },
    faceBookSignIn: {
      method: "get",
      endpoint: "api/auth/facebook",
    },
  },
  persistSlice: {},
});
