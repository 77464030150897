import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import Avatar from "../../assets/icons/Avatar.png";
import { StarIcon, ShoppingCartIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import LoadingComponent from "../../components/loading/LoadingComponent";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";

export default function ProductDetail() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const product_id = params.get("id");

  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const AccessToken = useSelector((state) => state.AccessToken);
  const productDetail = useSelector(
    (state) => state?.products?.productDetail?.data?.data
  );
  const [seeReview, setSeeReview] = useState(false);
  const [buyAmount, setBuyAmount] = useState(1);
  const [alertmessage, setAlertmessage] = useState("");

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    console.log(product_id);
    if (product_id) {
      callApi("products/productDetail")
        .withKeyParameter({ productSlug: product_id })
        .loadingGroup("productDetail")
        .executeDispatch();
    }
  }, [product_id]);

  async function handleAddtoCart() {
    callApi("cart/add")
      .withBody({
        product_id: productDetail?.id,
        qty: buyAmount,
      })
      .loadingGroup("addToCartLoading")
      .executeDispatch()
      .then((res) => {
        callApi("cart/list").executeDispatch();
        if (res?.status_code == 200) {
          setAlertmessage(
            buyAmount +
              " " +
              res?.data?.product?.name +
              " has been added to Cart"
          );
        }
      });
  }

  function BuyTicket() {
    navigate("/lucky-draw", { state: { buytokenid: productDetail?.id } });
  }

  function handleBuyNow() {
    navigate("/confirm-order", {
      state: { product: productDetail, qty: buyAmount },
    });
  }

  return (
    <>
      {alertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={alertmessage}
          onClick={() => setAlertmessage(null)}
        />
      )}

      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div
        id="body"
        className="bottombutton"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <LoadingComponent
          loadingGroup={"productDetail"}
          loadingDesign={
            <div className="flex justify-center content-center w-100 mt-5">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="container p-lr-16 m-b-24">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex space-x-2 fs-12">
                <li>
                  <button
                    onClick={() => navigate("/")}
                    className="text-gray-500 hover:text-red-500"
                  >
                    {getLanguageFile.home}
                  </button>
                </li>
                <li>
                  <span className="text-gray-500"> \ </span>
                </li>
                <li>
                  <button
                    onClick={() =>
                      navigate(
                        `/category?category_id=${productDetail?.category_id}`
                      )
                    }
                    className="text-gray-500 hover:text-red-500"
                  >
                    {productDetail?.category}
                  </button>
                </li>
                <li>
                  <span className="text-gray-500"> \ </span>
                </li>
                <li className="text-gray-500">{productDetail?.name}</li>
              </ol>
            </nav>
          </section>

          <section className="container p-lr-16 m-b-24 relative">
            <Swiper
              modules={[Navigation]}
              spaceBetween={8}
              slidesPerView={1.48}
              className="overflow-hidden relative"
            >
              <SwiperSlide>
                <img
                  src={
                    productDetail?.feature_image
                      ? productDetail?.feature_image
                      : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full"
                />
              </SwiperSlide>
              {productDetail?.product_images?.map((eachImage, key) => (
                <SwiperSlide key={key}>
                  <img src={eachImage} alt={key} className="w-full" />
                </SwiperSlide>
              ))}

              <div className="absolute bottom-3 left-2 flex items-center justify-between bg-white text-white font-semibold rounded-full p-lr-8 p-tb-4 m-l-4 z-1">
                <span className="text-black fs-12 font-medium">1 / 20</span>
              </div>
            </Swiper>
          </section>

          <section className="container p-lr-16 m-b-24 relative">
            <span className="flex justify-between">
              <span className="flex items-center leading-normal">
                <span className="fs-16 font-bold">MMK</span>
                <span className="fs-24 font-extrabold leading-[32px] me-2">
                  {productDetail?.actual_price}
                </span>

                {/* {productDetail?.off_price != 0 && (
                <>
                  <span className="flex items-center text-300 leading-normal">
                    <span className="fs-12">MMK</span>
                    <span className="fs-12 line-through leading-[16px]">
                      {productDetail?.off_price}
                    </span>
                  </span>

                  <div className="flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                    <span className="text-white fs-8 font-medium">?% off</span>
                  </div>
                </>
              )} */}
              </span>

              {productDetail?.type != "token-product" && (
                <button
                  onClick={() =>
                    AccessToken ? handleAddtoCart() : navigate("/signin")
                  }
                >
                  <LoadingComponent
                    loadingGroup={"addToCartLoading"}
                    loadingDesign={<ClipLoader color="#fed801" size={16} />}
                  >
                    <ShoppingCartIcon className="flex w-[25px] h-[25px] justify-center items-center shrink-0 text-500" />
                  </LoadingComponent>
                </button>
              )}
            </span>
          </section>

          {/* <section className="container p-lr-16 m-b-24 mt-1 m-b-16">
          <div className="flex items-center justify-between bg-gold-50 text-white font-semibold rounded-full border-[1px] border-gold-100 px-4 py-2">
            <span className="flex items-center">
              <span className="text-gold-900 fs-12 font-medium pe-1">
                get
              </span>
              <span className="text-gold-900 fs-12 font-bold">50</span>
              <span className="flex p-all-4 gap-[10px] rounded-full bg-gold-500 m-lr-4">
                <StarIcon id="StarIcon" className="flex w-[6px] h-[6px] justify-center items-center shrink-0 text-white" />
              </span>
              <span className="text-gold-900 fs-12 font-medium pe-1">
                for first time ordering
              </span>
            </span>

            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </div>
        </section> */}

          <section className="container p-lr-16 m-b-8">
            {/* <div className="flex gap-[2px]">
            <span className="fs-12 font-medium">3.9</span>

            <StarIcon
              id="StarIcon"
              className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
            />
            <StarIcon
              id="StarIcon"
              className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
            />
            <StarIcon
              id="StarIcon"
              className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
            />
            <StarIcon
              id="StarIcon"
              className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
            />
            <StarIcon
              id="StarIcon"
              className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-100"
            />

            <button
              onClick={() => setSeeReview(!seeReview)}
              className="fs-12 text-blue-500 m-l-16"
            >
              see all 52 reviews
            </button>
          </div> */}

            <div className="border-t-2 border-Light/active my-4"></div>
          </section>

          {/* <section className="container p-lr-16 m-b-8">
          <div className="fs-12 font-medium m-b-8">
            Color : 2
          </div>

          <div className="flex gap-[8px]">
            <div className="w-[30px] h-[30px] rounded-full bg-Light/active border-[1px] border-Grey/Darker" />
            <div className="w-[30px] h-[30px] rounded-full bg-red-300 border-[1px]" />
            <div className="w-[30px] h-[30px] rounded-full bg-green-300 border-[1px]" />
            <div className="w-[30px] h-[30px] rounded-full bg-gold-300 border-[1px]" />
          </div>

          <div className="border-t-2 border-Light/active my-4"></div>

        </section> */}

          <section className="container p-lr-16 m-b-8">
            <div
              dangerouslySetInnerHTML={{ __html: productDetail?.description }}
            />
            {/* <div className="fs-12 font-medium m-b-8">
            {productDetail?.description}
          </div> */}
          </section>

          {/* <section className="container p-lr-16 m-b-8">
          <div className="fs-12 font-medium m-b-8">
            {getLanguageFile.delivery_fee}
          </div>

          <div className="fs-12 font-bold m-b-8">
            MMK260,000
          </div>

          <div className="fs-12 m-b-8">
            estimated arrival time : 3 to 4 days
          </div>

          <div className="border-t-2 border-Light/active my-4"></div>

        </section> */}

          {seeReview && (
            <section className="container p-lr-16 m-b-8">
              <div className="fs-12 font-medium m-b-8">{"Reviews (52)"}</div>

              <div className="bg-foundation-grey-light-hover p-all-8 m-b-8">
                <div className="flex justify-between items-center">
                  <div className="flex items-center mb-1">
                    <img
                      src={Avatar}
                      alt="Slide 1"
                      className="flex w-[24px] items-center gap-[4px] me-1"
                    />
                    <span className="fs-12 font-medium">Kyaw Kyaw</span>
                  </div>

                  <span className="fs-12 text-300">30 Jan 2024</span>
                </div>

                <div className="flex gap-[2px] mb-1">
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-100"
                  />
                </div>

                <div className="fs-12 font-medium m-b-8">Excellent Product</div>

                <p className="fs-12 text-400">
                  4L Kitchen Mini Fridge Portable Refrigerator 110V 220V Warmer
                  Cooler Drink Beverage Cans Samll Freezer for Home Food Room
                </p>
              </div>

              <div className="bg-foundation-grey-light-hover p-all-8 m-b-8">
                <div className="flex justify-between items-center">
                  <div className="flex items-center mb-1">
                    <img
                      src={Avatar}
                      alt="Slide 1"
                      className="flex w-[24px] items-center gap-[4px] me-1"
                    />
                    <span className="fs-12 font-medium">Kyaw Kyaw</span>
                  </div>

                  <span className="fs-12 text-300">30 Jan 2024</span>
                </div>

                <div className="flex gap-[2px] mb-1">
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-100"
                  />
                </div>

                <div className="fs-12 font-medium m-b-8">Excellent Product</div>

                <p className="fs-12 text-400">
                  4L Kitchen Mini Fridge Portable Refrigerator 110V 220V Warmer
                  Cooler Drink Beverage Cans Samll Freezer for Home Food Room
                </p>
              </div>

              <div className="bg-foundation-grey-light-hover p-all-8 m-b-8">
                <div className="flex justify-between items-center">
                  <div className="flex items-center mb-1">
                    <img
                      src={Avatar}
                      alt="Slide 1"
                      className="flex w-[24px] items-center gap-[4px] me-1"
                    />
                    <span className="fs-12 font-medium">Kyaw Kyaw</span>
                  </div>

                  <span className="fs-12 text-300">30 Jan 2024</span>
                </div>

                <div className="flex gap-[2px] mb-1">
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-gold-500"
                  />
                  <StarIcon
                    id="StarIcon"
                    className="flex w-[16px] h-[16px] justify-center items-center shrink-0 text-100"
                  />
                </div>

                <div className="fs-12 font-medium m-b-8">Excellent Product</div>

                <p className="fs-12 text-400">
                  4L Kitchen Mini Fridge Portable Refrigerator 110V 220V Warmer
                  Cooler Drink Beverage Cans Samll Freezer for Home Food Room
                </p>
              </div>
            </section>
          )}

          <section className="container p-lr-16 m-b-8">
            <div className="flex items-center justify-between mb-4">
              <p className="fs-14 font-bold">{getLanguageFile.quantity}</p>
              <div className="flex items-center justify-between">
                <button
                  onClick={() => setBuyAmount(buyAmount - 1)}
                  className="bg-gray-200 w-8 h-8 flex items-center justify-center rounded-full"
                >
                  -
                </button>
                <span className="fs-14 font-bold mx-2">{buyAmount}</span>
                <button
                  onClick={() => setBuyAmount(buyAmount + 1)}
                  className="bg-gray-200 w-8 h-8 flex items-center justify-center rounded-full"
                >
                  +
                </button>
              </div>
            </div>
            {productDetail?.actual_price && (
              <div className="flex items-center justify-end me-1">
                Total: {productDetail?.actual_price * buyAmount}
              </div>
            )}
          </section>
        </LoadingComponent>
      </div>

      <div className="w-100 p-lr-16 max-w-[393px] bottom-0 bg-white shadow-md-top">
        <div
          className={`${
            productDetail?.type != "token-product"
              ? "grid grid-cols-2 gap-2"
              : "grid grid-cols-1 gap-2"
          }`}
        >
          <LoadingComponent
            loadingGroup={"productDetail"}
            loadingDesign={
              <>
                <div className="flex items-center justify-center m-tb-16 bg-white text-white font-semibold rounded-full p-lr-32 p-tb-12 w-100">
                  Dan
                </div>
                <div className="flex items-center justify-center m-tb-16 bg-white text-white font-semibold rounded-full p-lr-32 p-tb-12 w-100">
                  Demo
                </div>
              </>
            }
          >
            <button
              onClick={() =>
                AccessToken ? handleBuyNow() : navigate("/signin")
              }
              className="flex items-center justify-center m-tb-16 bg-white text-500 font-semibold border-[1px] border-gold-500 rounded-full p-lr-32 p-tb-12 w-100"
            >
              {getLanguageFile.buy_now}
            </button>

            {productDetail?.type != "token-product" && (
              <button
                onClick={() =>
                  AccessToken ? BuyTicket() : navigate("/signin")
                }
                className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
              >
                {getLanguageFile.buy_token}
              </button>
            )}
          </LoadingComponent>
        </div>
      </div>
    </>
  );
}
