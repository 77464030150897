// BuyTicketModal.jsx
import React, { useEffect, useState } from "react";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";

const BuyTicketModal = ({
  show,
  onClose,
  product,
  onConfirm,
  buyAmount,
  setBuyAmount,
  setTokenNotEnoughError,
}) => {
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);

  useEffect(() => {
    return () => {
      setChangeLanguage(window.localStorage.currentLanguage || "en")
    }
  }, [show])
  
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg relative w-[90%] max-w-[400px]">
        <button onClick={onClose} className="absolute top-2 right-2">
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <h2 className="fs-14 font-bold mb-4">
          {getLanguageFile.buy_ticket_product}
        </h2>
        <div className="flex items-center mb-4 relative">
          <img
            src={
              product?.feature_image ? product?.feature_image : LuckydrawItem1
            }
            alt={product?.name}
            className="w-1/3 rounded-md"
          />
          <div className="ml-4">
            <h3 className="fs-14 font-bold">{product?.name}</h3>
            <p className="fs-11">{product?.label}</p>
            <p className="fs-11">{getLanguageFile.original_price}</p>
            <p className="fs-11 font-bold">{product?.actual_price}</p>
          </div>
          {/* <div className="absolute top-2 right-2 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
            <span className="text-white fs-8 font-medium">2 {getLanguageFile.day_left}</span>
          </div> */}
        </div>
        <div className="mb-4">
          <p className="fs-11">
            {getLanguageFile.remaining_tickets}:{" "}
            <span>{product?.remaining_tickets}</span> / {product?.ticket}
          </p>
          <div className="w-full bg-gray-300 h-3 rounded-full">
            <div
              className="bg-yellow-400 h-3 rounded-full"
              style={{
                width: `${
                  (product?.remaining_tickets / product?.ticket) * 100
                }%`,
              }}
            ></div>
          </div>
          <p className="fs-12 mt-2 font-bold">
            {getLanguageFile.only_bla_ticket_remains_1}{" "}{product?.remaining_tickets}{" "}{getLanguageFile.only_bla_ticket_remains_2}
          </p>
          <p className="fs-12 mt-2 font-bold">
            {getLanguageFile.will_declare_lucky_num_after_10_min}
          </p>
        </div>
        <div className="flex items-center justify-between mb-4">
          <p className="fs-14 font-bold">{getLanguageFile.ticket_count}</p>
          <div className="flex items-center justify-between">
            <button
              onClick={() => setBuyAmount(buyAmount - 1)}
              className="bg-gray-200 w-8 h-8 flex items-center justify-center rounded-full"
            >
              -
            </button>
            <span className="fs-14 font-bold mx-2">{buyAmount}</span>
            <button
              onClick={() => setBuyAmount(buyAmount + 1)}
              className="bg-gray-200 w-8 h-8 flex items-center justify-center rounded-full"
            >
              +
            </button>
          </div>
        </div>
        <button
          className="w-full bg-gold-500 px-4 py-2 rounded-full flex items-center justify-center"
          onClick={() => {
            if (product?.cost_token_point * buyAmount > profile?.token_point) {
              setTokenNotEnoughError(getLanguageFile?.token_not_enough);
            } else {
              onClose();
              onConfirm();
            }
          }}
        >
          <span className="flex items-center justify-center p-1 bg-white rounded-full mr-2">
            <StarIcon
              id="StarIcon"
              className="w-[12px] h-[12px] text-gold-500"
            />
          </span>
          <span className="fs-14 font-semibold">
            {product?.cost_token_point * buyAmount} {getLanguageFile.tokens}
          </span>
        </button>
      </div>
    </div>
  );
};

export default BuyTicketModal;
