import React, { useEffect } from 'react'
import AppLayout from "./routers";
import callApi from './services/api/apiClient';
import { useSelector } from 'react-redux';

const App = () => {
  const setting = useSelector(state => state?.others?.setting?.data?.data);

  useEffect(() => {
    callApi("others/setting")
      .executeDispatch()
  }, []);

  useEffect(() => {
    if (setting?.site_favicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = setting?.site_favicon;
    }
    if (setting?.site_name) {
      document.title = setting?.site_name;
    }
  }, [setting]);

  return (
    <AppLayout />
  )
}



export default App;