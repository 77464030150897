import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../layout/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import Banner1 from '../../assets/images/banner1.png'
import LuckydrawItem1 from '../../assets/images/LuckydrawItem1.jpg'
import { ArrowLeftIcon, StarIcon, PencilSquareIcon, PlusIcon, MinusIcon, HomeIcon, ShoppingCartIcon, GiftIcon } from '@heroicons/react/24/solid'
import { UserCircleIcon } from '@heroicons/react/24/outline';
import BottomNav from '../../layout/BottomNav';
import AuthHeader from '../../layout/AuthHeader';

export default function MyOrdersReceived() {
  const navigate = useNavigate();
  const [changeLanguage, setChangeLanguage ] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  return (
    <>
      <AuthHeader title={getLanguageFile.my_orders} backkey={true} />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>

        <section className="container p-lr-16 m-b-16">

          <div className="flex m-b-24">
            <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-light-hover">
              <button onClick={() => navigate("/my-orders-to-ship")} className="text-500 fs-12 font-medium p-all-8">
                {getLanguageFile.to_ship}
              </button>
            </div>

            <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-light-hover">
              <button onClick={() => navigate("/my-orders-shipping")} className="text-500 fs-12 font-medium p-all-8">
                {getLanguageFile.shipping}
              </button>
            </div>

            <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-normal-hover">
              <button onClick={() => navigate("/my-orders-received")} className="text-500 fs-12 font-medium p-all-8">
                {getLanguageFile.received}
              </button>
            </div>
          </div>

        </section>


        <section className="container p-lr-16 m-b-16">

          <div className="flex flex-col">

            {/* <div className="flex relative">

              <div href="product-detail" className="w-5/12 relative">
                <img src={LuckydrawItem1} alt="Slide 1" className="" />

                <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">
                    60% off
                  </span>
                </div>
              </div>

              <div className="flex-grow w-7/12 p-lr-8 p-tb-16">
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                  Household refrigerator small double door
                </div>
                <span className="flex items-center leading-normal">
                  <span className="fs-11 font-bold me-1">
                    MMK
                  </span>
                  <span className="fs-16 font-extrabold leading-[16px]">
                    260,000
                  </span>
                </span>
                <span className="flex items-center text-300 leading-normal">
                  <span className="fs-11 me-1">
                    was
                  </span>
                  <span className="fs-11">
                    MMK
                  </span>
                  <span className="fs-11 line-through leading-[16px] me-1">
                    560,000
                  </span>
                  <span className="fs-11 font-medium text-500">
                    60% off
                  </span>
                </span>

                <div className="m-b-16">
                  <span className="fs-11 text-300 leading-normal me-1">
                    {getLanguageFile.shipping}
                  </span>

                  <span className="fs-11 text-red-500 leading-[16px]">
                    MMK 5,000
                  </span>
                </div>

                <div className="flex m-b-16">
                  <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                    <span className="text-500 fs-12 font-medium">
                      White
                    </span>
                  </div>

                  <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                    <span className="text-500 fs-12 font-medium">
                      Small
                    </span>
                  </div>
                </div>

                <div className="flex m-b-8">
                  <div className="flex items-center justify-between bg-foundation-grey-light-hover text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                    <span className="text-500 fs-12 font-semibold">
                    {getLanguageFile.received_date} : 23/06/2024
                    </span>
                  </div>
                  
                </div>
                

              </div>


            </div>

            <div className="border-t-2 border-Light/active mb-4"></div>


            <div className="flex relative">

              <div href="product-detail" className="w-5/12 relative">
                <img src={LuckydrawItem1} alt="Slide 1" className="" />

                <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">
                    60% off
                  </span>
                </div>
              </div>

              <div className="flex-grow w-7/12 p-lr-8 p-tb-16">
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                  Household refrigerator small double door
                </div>
                <span className="flex items-center leading-normal">
                  <span className="fs-11 font-bold me-1">
                    MMK
                  </span>
                  <span className="fs-16 font-extrabold leading-[16px]">
                    260,000
                  </span>
                </span>
                <span className="flex items-center text-300 leading-normal">
                  <span className="fs-11 me-1">
                    was
                  </span>
                  <span className="fs-11">
                    MMK
                  </span>
                  <span className="fs-11 line-through leading-[16px] me-1">
                    560,000
                  </span>
                  <span className="fs-11 font-medium text-500">
                    60% off
                  </span>
                </span>

                <div className="m-b-16">
                  <span className="fs-11 text-300 leading-normal me-1">
                    {getLanguageFile.shipping}
                  </span>

                  <span className="fs-11 text-red-500 leading-[16px]">
                    MMK 5,000
                  </span>
                </div>

                <div className="flex m-b-8">
                  <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                    <span className="text-500 fs-12 font-medium">
                      White
                    </span>
                  </div>

                  <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                    <span className="text-500 fs-12 font-medium">
                      Small
                    </span>
                  </div>
                </div>

                <div className="flex m-b-8">
                  <div className="flex items-center justify-between bg-foundation-grey-light-hover text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                    <span className="text-500 fs-12 font-semibold">
                    {getLanguageFile.received_date} : 23/06/2024
                    </span>
                  </div>
                  
                </div>
                

              </div>
            </div> */}

          </div>
        </section>

      </div>

      
    </>
  )
}