import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AuthHeader from '../../layout/AuthHeader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import * as Yup from "yup";
import { dispatchStore } from '../../lib/dispatchStore';
import { AccessTokenSlice } from '../../redux/reducers/reducer';
import callApi from '../../services/api/apiClient';
import { useFormik } from 'formik';
import AlertPopup from '../../components/AlertPopUp/AlertPopUp';




export default function SignIn() {
  // Change language
  const [changeLanguage, setChangeLanguage ] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const SignInSchema = Yup.object().shape({
    phone: Yup.string()
      .required(getLanguageFile.phone_number_require),
    password: Yup.string()
      .min(6, getLanguageFile.password_much_six_char)
      .required(getLanguageFile.password_require),
  });

  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState(null);



  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SignInSchema,
    onSubmit: (values, actions) => {
      callApi("auth/signin")
        .withBody({
          phone: values.phone,
          password: values.password,
        })
        .loadingGroup("signup")
        .execute()
        .then((res) => {
          console.log(res)
          if (res?.token) {
            dispatchStore(AccessTokenSlice.actions.setAccessToken("Bearer " + res?.token));
          }
          if (res?.status_code == 200) {
            actions.resetForm();
            navigate("/");
          }
          else {
            setServerError(res?.message)
          }
        })
        .catch((err) => console.log("error", err));
      actions.setSubmitting(false);
    },
  });

  const handleClose = () => {
    setServerError(null)
  };

  return (
    <>
      {
        serverError &&
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={handleClose}
        />
      }

      <AuthHeader title={getLanguageFile.sign_in} backkey={true} />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>

        <section className="m-tb-32">
          <h1 className="fs-24 font-semibold leading-[30px] text-red-500 text-center">
            Lucky Mall
          </h1>
        </section>

        <section className="container p-lr-16 m-b-8">
          <form action="" onSubmit={formik.handleSubmit}>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
               {getLanguageFile.phone_number} 
                <span className="text-red-500">*</span>
                {formik?.errors.phone && (
                  <span className="text-red-500">
                    {formik.errors.phone}
                  </span>
                )}
              </label>

              <input
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}

                type="text"
                placeholder={getLanguageFile.type_phone_number}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />

            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.password}
                <span className="text-red-500">*</span>
                {formik?.errors.password && (
                  <span className="text-red-500">
                    {formik.errors.password}
                  </span>
                )}
              </label>

              <span className="flex justify-between items-center text-gray-500  p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}

                  type={showPassword ? "text" : "password"}
                  placeholder={getLanguageFile.type_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                />
                <button type="button" onClick={() => setShowPassword(!showPassword)}>
                  {
                    showPassword
                      ?
                      <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                      :
                      <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />

                  }
                </button>
              </span>
              <span className="fs-12 font-medium text-blue-500 text-end">{getLanguageFile.forget_password}?</span>

            </div>

            <button 
            disabled={
              Object.keys(formik.errors).length > 0
              ||
              formik.values.phone === ""
              ||
              formik.values.password === ""
            }
            type={"submit"}
            className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50">
              {getLanguageFile.sign_in}
            </button>

            <h2 className="flex items-center justify-center m-b-8">
              <span className="fs-12 font-medium text-black text-center pe-1">
                {getLanguageFile.dont_have_account}?
              </span>
              <button type="button" onClick={() => navigate("/signup")} className="text-blue-500 font-medium fs-12">{getLanguageFile.sign_up}</button>
            </h2>

          </form>
        </section>



      </div>
    </>
  )
}