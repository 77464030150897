import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AuthHeader from '../../layout/AuthHeader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { EyeIcon } from '@heroicons/react/24/outline'
import Avatar from '../../assets/icons/Avatar.png'
import * as Yup from "yup";
import callApi from '../../services/api/apiClient';
import { useSelector } from 'react-redux';
import { Formik, Field, useFormik } from 'formik';
import { dispatchStore } from '../../lib/dispatchStore';
import AvatarEditor from 'react-avatar-editor'

const CreateProfileSchema = Yup.object().shape({
  profile_image: Yup.mixed().nullable(),
  region_id: Yup.string()
    .required("Region is required"),
  township_id: Yup.string()
    .required("Township is required"),
  address: Yup.string()
    .required("Address is required"),
  postal_code: Yup.string()
});

async function displayEditorAsImage(editor) {
  if (editor?.type) {
  }
  else if(Object.keys(editor).length === 0){

  }
  else {
    const dataUrl = editor?.getImage()?.toDataURL()
    const res = await fetch(dataUrl)
    const imageBlob = await res.blob()
    return window.URL.createObjectURL(imageBlob)
  }
}

export default function CreateProfile() {
  const regions = useSelector(state => state?.others?.regions?.data?.data);
  const townships = useSelector(state => state?.others?.townships?.data?.data);
  const navigate = useNavigate()
  const editor = useRef(null);
  const [imageEdit, setImageEdit] = useState(false);
  const [imageHolder, setImageHolder] = useState(null);
  const [displayImage, setDisplayImage] = useState();

  const [changeLanguage, setChangeLanguage ] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);


  
  const formik = useFormik({
    initialValues: {
      profile_image: {},
      region_id: "",
      township_id: "",
      address: "",
      postal_code: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CreateProfileSchema,
    onSubmit: (values, actions) => {
      if (Object.keys(values?.profile_image).length > 0) {
        values?.profile_image?.getImage().toBlob(function (blob) {
          const formData = new FormData();
          formData.append('profile_image', blob, 'profile_image.png')
          formData.append('region_id', values.region_id);
          formData.append('township_id', values.township_id);
          formData.append('address', values.address);
          formData.append('postal_code', values.postal_code);

          callApi("auth/createprofile")
            .withBody(formData)
            .loadingGroup("createprofile")
            .execute()
            .then((res) => {
              console.log(res, "auth/createprofile")
              if (res?.status_code == 200) {
                // actions.resetForm();
                navigate("/onboarding");
              }
              else {

              }
            })
            .catch((err) => console.log("error", err));

        })
      }
      else {
        const formData = new FormData();
        formData.append('region_id', values.region_id);
        formData.append('township_id', values.township_id);
        formData.append('address', values.address);
        formData.append('postal_code', values.postal_code);

        callApi("auth/createprofile")
          .withBody(formData)
          .loadingGroup("createprofile")
          .execute()
          .then((res) => {
            console.log(res, "auth/createprofile")
            if (res?.status_code == 200) {
              // actions.resetForm();
              navigate("/onboarding");
            }
            else {

            }
          })
          .catch((err) => console.log("error", err));
      }
      actions.setSubmitting(false);
    },
  });
  console.log(imageEdit == true, "a")
  console.log(Object.keys(formik.errors).length > 0, "b")
  // profile_image must be a `object` type, but the final value was: `{}`.
  useEffect(() => {
    callApi("others/regions")
      .executeDispatch()
  }, []);

  useEffect(() => {
    (async () => {
      const a = await displayEditorAsImage(formik.values.profile_image)
      setDisplayImage(a)
    })();
  }, [formik.values.profile_image]);

  useEffect(() => {
    if (regions?.length > 0 && !formik.values.region_id) {
      const yangonId = regions?.find(region => region?.name === "Yangon")?.id;
      formik.setFieldValue('region_id', yangonId);
      callApi("others/townships")
        .withKeyParameter({ regionid: yangonId })
        .executeDispatch()
    }
    else {
      if (formik.values.region_id) {
        callApi("others/townships")
        .withKeyParameter({ regionid: formik.values.region_id })
        .executeDispatch()
      }
    }
  }, [regions, formik.values.region_id]);

  useEffect(() => {
    if (townships?.length > 0 && !formik.values.township_id) {
      formik.setFieldValue('township_id', townships?.[0]?.id);
    }
  }, [townships]);

  const handleImageChange = (event) => {
    setImageEdit(true)
    setImageHolder(event.currentTarget.files[0])
    formik.setFieldValue('profile_image', event.currentTarget.files[0]);
  };

  async function cropImage() {
    if (editor) {
      formik.setFieldValue('profile_image', editor?.current);
      // canvas.toBlob((blob) => {
      //   const formData = new FormData();
      //   console.log(canvas)
      //   formData.append('profile_image', blob, 'profile_image.png');
      // })
    }
    setImageEdit(false)
  }


  return (
    <>
      <AuthHeader title={getLanguageFile.create_profile} backkey={true} />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>

        <form action="" onSubmit={formik.handleSubmit}>
          <section className="m-tb-32">
            <h1 className="fs-14 font-semibold leading-[16px] text-500 text-center">
              {getLanguageFile.add_avatar_image}
            </h1>

            <div className="relative w-[120px] h-[120px] m-t-24 mx-auto">
              {imageEdit == true ? (
                <>
                  <AvatarEditor
                    ref={editor}
                    className="w-[120px!important] h-[120px!important] object-cover"
                    image={imageHolder}
                    border={50}
                  />
                  <button onClick={cropImage}>{getLanguageFile.save}</button>
                </>

                // <img src={URL.createObjectURL(formik?.values?.profile_image)} className="w-[120px] h-[120px] rounded-full object-cover" alt="Selected Avatar" />
              ) : (
                <img src={
                  formik?.values?.profile_image && displayImage
                    ?
                    displayImage
                    :
                    Avatar
                } className="w-[120px] h-[120px] rounded-full object-cover" alt="Default Avatar" />
              )}
              <label
                htmlFor="profile_image"
                className="absolute bottom-0 right-0 bg-white rounded-full p-2 border-[2px] border-gray-300 cursor-pointer"
              >
                <input
                  id="profile_image"
                  name="profile_image"

                  type="file"
                  className="hidden"
                  onChange={handleImageChange}
                  onBlur={formik.handleBlur}
                  accept="image/*"
                />
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-gray-500"
                >
                  <path d="M1.99915 11.6409V13.6676C1.99915 13.8543 2.14581 14.0009 2.33248 14.0009H4.35915C4.44581 14.0009 4.53248 13.9676 4.59248 13.9009L11.8725 6.6276L9.37248 4.1276L2.09915 11.4009C2.03248 11.4676 1.99915 11.5476 1.99915 11.6409ZM13.8058 4.69427C14.0658 4.43427 14.0658 4.01427 13.8058 3.75427L12.2458 2.19427C11.9858 1.93427 11.5658 1.93427 11.3058 2.19427L10.0858 3.41427L12.5858 5.91427L13.8058 4.69427Z" fill="#8C939F" />
                </svg>
              </label>
              {formik?.errors.profile_image && (
                <span className="text-red-500 fs-12 font-semibold">
                  {formik.errors.profile_image}
                </span>
              )}
            </div>


          </section>

          <section className="container p-lr-16 m-b-8">

            <h2 className="fs-14 font-bold leading-[16px] text-500 m-b-16">
              {getLanguageFile.shipping_info}
            </h2>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.state_or_region}
                <span className="text-red-500">*</span>
                {formik?.errors.region_id && (
                  <span className="text-red-500">
                    {formik.errors.region_id}
                  </span>
                )}
              </label>
              <select
                id="region_id"
                name="region_id"
                onChange={formik.handleChange}
                value={formik.values.region_id}
                onBlur={formik.handleBlur}

                className="text-gray-500 bg-white p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md">
                {
                  regions?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>{item?.name}</option>
                    )
                  })
                }
              </select>
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.township}
                <span className="text-red-500">*</span>
                {formik?.errors.township_id && (
                  <span className="text-red-500">
                    {formik.errors.township_id}
                  </span>
                )}
              </label>
              <select
                id="township_id"
                name="township_id"
                onChange={formik.handleChange}
                value={formik.values.township_id}
                onBlur={formik.handleBlur}

                className="text-gray-500 bg-white p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md">
                {
                  townships?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>{item?.name}</option>
                    )
                  })
                }
              </select>

            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.street_address}
                <span className="text-red-500">*</span>
                {formik?.errors.address && (
                  <span className="text-red-500">
                    {formik.errors.address}
                  </span>
                )}
              </label>
              <input
                id="address"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}

                type="text"
                placeholder="43 street"
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.postal_code}
              </label>
              {formik?.errors.postal_code && (
                <span className="text-red-500">
                  {formik.errors.postal_code}
                </span>
              )}
              <input
                id="postal_code"
                name="postal_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postal_code}

                type="text"
                placeholder="Add postal code"
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>


          </section>

          <div className="w-100 p-all-16 bottom-0 bg-white">
            <button
              disabled={imageEdit == true || Object.keys(formik.errors).length > 0}
              className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50">
              {getLanguageFile.create_profile}
            </button>
          </div>
        </form>

      </div>
    </>
  )
}