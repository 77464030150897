import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Bars3Icon, StarIcon } from "@heroicons/react/24/solid";
import {
  ShoppingBagIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MMLanguageIcon from "../assets/icons/MMlanguage_icon.png";
import ENLanguageIcon from "../assets/icons/ENlanguage_icon.png";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import { useSelector } from "react-redux";
import callApi from "../services/api/apiClient";
import LanguageModal from "../screens/user/LanguageModal";
import mm from '../assets/images/flags/mm.svg';
import en from '../assets/images/flags/en.svg';
import ch from '../assets/images/flags/ch.svg';

const Header = ({ changeLanguageClick = null, getLanguageFile = [] }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openCategory, setopenCategory] = useState(false);
  const [searchText, setSearchText] = useState("");

  const AccessToken = useSelector((state) => state.AccessToken);
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);
  const categories = useSelector(
    (state) => state?.home?.categories?.data?.data
  );
  const setting = useSelector((state) => state?.others?.setting?.data?.data);
  const cartList = useSelector((state) => state?.cart?.list?.data?.data);
  const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);
  const [isDefaultUs, setIsDefaultUs] = useState(window.localStorage.defaultStatus || true);
  const [currentLanguage, setCurrentLanguage] = useState(window.localStorage.currentLanguage || 'en');


  const changeLanguage = (language) => {
    
    // if(isDefaultUs) {
    //   setCurrentLanguage("mm") 
    //   changeLanguageClick("mm");
    //   setIsDefaultUs(false);
    //   window.localStorage.setItem("currentLanguage", "mm");
    //   window.localStorage.setItem("defaultStatus", false);
    // } else {
    //   setCurrentLanguage("en");
    //   changeLanguageClick("en");
    //   setIsDefaultUs(true);
    //   window.localStorage.setItem("currentLanguage", "en");
    //   window.localStorage.setItem("defaultStatus", true);
    // }
    setShowBuyTicketModal(false);
    (language != undefined) ? changeLanguageClick(language) : changeLanguageClick('en');
    setCurrentLanguage(language);

  };

  const flagCircleLayout = {
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "50%"
  }

  useEffect(() => {
    callApi("home/categories")
      .withHeaders({ "X-localization": window.localStorage.currentLanguage })
      .executeDispatch();
  }, [window.localStorage.currentLanguage]);

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile").executeDispatch();

      callApi("cart/list").executeDispatch();
    }
  }, [AccessToken]);

  return (
    <>
      <header
        id="Top_Navbar"
        className="flex p-tb-9 p-lr-16 flex-col self-stretch shadow-md"
      >
        <nav id="Nav_Items" className="flex flex-col gap-[16px]">
          <div id="Top_Bar" className="flex justify-between self-stretch">
            <div id="Left_Group" className="flex w-[111px] items-center">
              <button
                className="flex items-center"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Bars3Icon
                  id="Hamburger_menu"
                  className="flex w-[24px] h-[24px] justify-center items-center shrink-0"
                />
              </button>
              <button
                onClick={() => navigate("/")}
                id="Logo_Container"
                className="flex items-center gap-10"
              >
                {setting?.site_logo ? (
                  <img
                    src={setting?.site_logo}
                    alt=""
                    className="w-[auto] h-[20px] ms-1"
                  />
                ) : (
                  <span className="text-red-500 fs-16 font-semibold lh-18 text-nowrap">
                    {setting?.site_name}
                  </span>
                )}
              </button>
            </div>

            <div id="Right_Group" className="flex items-center gap-[8px]">
              <button
                onClick={() => navigate("/cart-list")}
                id="Frame_146"
                className="flex w-[30px] h-[30px] justify-center items-center gap-[10px] rounded-full bg-gray-100 relative"
              >
                <ShoppingBagIcon
                  id="ShoppingBagIcon"
                  className="w-[20px] h-[20px] flex-shrink-0"
                />
                {cartList?.length > 0 && (
                  <div
                    id="Frame_147"
                    className="flex w-[12px] h-[12px] p-all-2 flex-col justify-center items-center gap-[10px] absolute right-[-1px] top-[-4px] rounded-full bg-red-500"
                  >
                    <span className="text-white fs-8 font-light">
                      {cartList?.length}
                    </span>
                  </div>
                )}
              </button>

              <div
                id="Frame_131"
                className="flex p-tb-4 p-l-12 p-r-4 items-center gap-[4px] rounded-3xl border-[1px] bg-yellow-100 border-yellow-200"
              >
                <span className="fs-12 font-semibold leading-normal text-yellow-900">
                  {profile?.token_point ? profile?.token_point : 0}
                </span>
                <div
                  id="Frame_130"
                  className="flex p-all-4 items-center gap-[10px] rounded-3xl bg-yellow-500"
                >
                  <StarIcon
                    id="ShoppingBagIcon"
                    className="w-[14px] h-[14px] text-white"
                  />
                </div>
              </div>

              <div
                id="Frame_54"
                className="flex w-[33px] h-[33px] p-[6.452px] flex-col gap-[8.065px] rounded-3xl bg-gray-100"
              >
                <div
                  id="Language_group"
                  className="w-[20.239pxpx] h-[18.477px] flex-shrink-0"
                  onClick={() => setShowBuyTicketModal(true) }
                >
                  <img 
                  className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
                  src=
                  {
                  (currentLanguage == 'en')
                  ? en
                  : (currentLanguage == 'ch')
                  ? ch : mm
                  } 
                  style={flagCircleLayout}/>
                </div>
              </div>
              {/* <span id="VerticleLine" className="w-[0.807px] h-[26.356px] bg-gray-200"></span> */}
            </div>
          </div>

          <div id="Bottom_Bar" className="flex justify-between self-stretch">
            <div
              id="Search_Bar"
              className="flex p-all-8 items-center gap-[8px] self-stretch rounded-full bg-gray-100 w-100"
            >
              <MagnifyingGlassIcon
                id="MagnifyingGlassIcon"
                className="w-[16px] h-[16px] text-gray-500 flex-shrink-0"
              />
              <input
                type="text"
                placeholder={getLanguageFile.searchinluckey}
                className="bg-gray-100 text-gray-500 w-100"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/search-result?search=${searchText}`);
                  }
                }}
              />
            </div>
          </div>
        </nav>
      </header>

      <nav>
        <div
          className={`absolute z-2 w-[250px] h-[100dvh] top-0 ${
            isOpen ? "left-0" : "left-[-250px]"
          } 
             bg-white text-500`}
        >
          <div className="p-4 h-full flex flex-col justify-between">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold"></h1>
                <button
                  onClick={() => setIsOpen(false)}
                  className="flex justify-center items-center w-[40px] h-[40px] rounded-full bg-foundation-grey-light-hover"
                >
                  <XMarkIcon className="w-[20px] h-[20px] text-500 flex-shrink-0" />
                </button>
              </div>
              <ul className="fs-16 font-bold mt-4" style={{ color: "#1E3B62" }}>
                <li className={`my-2 ${openCategory && "hidden"}`}>
                  <button
                    onClick={() => navigate("/")}
                    className="hover:text-gold-900 my-2"
                  >
                    {getLanguageFile.home}
                  </button>
                </li>
                <li className={`my-2 ${openCategory && "hidden"}`}>
                  <button
                    onClick={() => navigate("/prize-winners")}
                    className="hover:text-gold-900 my-2"
                  >
                    {getLanguageFile.lucky_draw_winner}
                  </button>
                </li>
                <li className="my-2 flex flex-col justify-center">
                  <button
                    onClick={() => setopenCategory(!openCategory)}
                    className={`flex justify-between items-center hover:text-gold-900 my-2 w-full text-left`}
                  >
                    <ChevronLeftIcon
                      className={`w-[20px] h-[20px] flex-shrink-0 me-1 ${
                        !openCategory && "hidden"
                      }`}
                    />
                    <span className="w-full">
                      {getLanguageFile.shop_by_categories}
                    </span>
                    <ChevronRightIcon
                      className={`w-[20px] h-[20px] flex-shrink-0 ${
                        openCategory && "hidden"
                      }`}
                    />
                  </button>
                  <ul className="fs-14 font-medium text-300 m-l-25">
                    {categories?.map((item, key) => (
                      <li key={key} className={`${!openCategory && "hidden"}`}>
                        <button
                          onClick={() => {
                            setIsOpen(false);
                            navigate(`/category?category_id=${item?.id}`);
                          }}
                          className="hover:text-gold-900 my-2"
                        >
                          {item?.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>

            <ul
              className="bottom-0 fs-12 font-semibold"
              style={{ color: "#1E3B62" }}
            >
              <li className={`my-2 ${openCategory && "hidden"}`}>
                <button
                  onClick={() =>
                    AccessToken ? navigate("/cart-list") : navigate("/signin")
                  }
                  className="hover:text-gold-900 my-2"
                >
                  {getLanguageFile.my_cart}
                </button>
              </li>
              <li className={`my-2 ${openCategory && "hidden"}`}>
                <button
                  onClick={() =>
                    AccessToken ? navigate("/profile") : navigate("/signin")
                  }
                  className="hover:text-gold-900 my-2"
                >
                  {getLanguageFile.my_account}
                </button>
              </li>
              <li className={`my-2 ${openCategory && "hidden"}`}>
                <button
                  onClick={() => navigate("/onboarding")}
                  className="hover:text-gold-900 my-2"
                >
                  {getLanguageFile.user_guide}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`absolute z-3 w-[250px] h-[100dvh] top-0 left-[-250px] 
             bg-black text-white`}
        ></div>
      </nav>

      <LanguageModal
        show={showBuyTicketModal}
        changeLanguageClick={changeLanguage}
        getLanguageFile={getLanguageFile}
        onClose={() => setShowBuyTicketModal(false)}
      />
    </>
  );
};

export default Header;
