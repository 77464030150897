import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import { StarIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import BottomNav from "../../layout/BottomNav";

export default function SearchResult() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const searchText = params.get("search");
  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const searchResult = useSelector(
    (state) => state?.products?.search?.data?.data
  );

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    console.log(searchText);
    if (searchText) {
      callApi("products/search")
        .withKeyParameter({ search: searchText })
        .executeDispatch();
    }
  }, [searchText]);

  return (
    <>
      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <section className="container p-lr-16 m-b-16">
          <h1 className="fs-16 font-bold m-b-16">
            {getLanguageFile.best_match}
          </h1>

          <div className="grid grid-cols-2 gap-2">
            {searchResult?.map((item, key) => (
              <button
              onClick={()=> navigate(`/product-detail?id=${item?.slug}`)}
                key={key}
                className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md"
              >
                {/* <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">60% off</span>
                </div> */}

                  <img
                    src={
                      item?.feature_image ? item?.feature_image : LuckydrawItem1
                    }
                    alt="Slide 1"
                    className="w-full rounded-t-md"
                  />

                <div className="p-lr-8 p-tb-16 text-left">
                  <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                    {item?.name}
                  </div>
                  <span className="fs-11">
                          {item?.category}
                        </span>
                  <span className="flex items-center leading-normal">
                    <span className="fs-11 font-bold me-1">MMK</span>
                    <span className="fs-16 font-extrabold leading-[16px]">
                      {item?.actual_price}
                    </span>
                  </span>

                  {/* <span className="fs-11 text-red-500 leading-[16px]">
                    {getLanguageFile.free_shipping}
                  </span> */}
                </div>
              </button>
            ))}
          </div>
        </section>

        <section className="container p-lr-16 m-b-16"></section>
      </div>

      <BottomNav
        active={1}
        getLanguageFile={getLanguageFile}
      />
    </>
  );
}
