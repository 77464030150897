import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  ArrowLeftIcon,
  StarIcon,
  PencilSquareIcon,
  PlusIcon,
  MinusIcon,
  HomeIcon,
  ShoppingCartIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import AuthHeader from "../../layout/AuthHeader";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import PaymentMethodModal from "../LuckyDraw/PaymentMethodModal";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import { slice } from "../../redux/reducers/reducer";
import { dispatchStore } from "../../lib/dispatchStore";

export default function MyOrdersToShip() {
  const navigate = useNavigate();

  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [serverError, setServerError] = useState(null);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const AccessToken = useSelector((state) => state.AccessToken);
  const orderList = useSelector((state) => state?.history?.order?.data?.data);

  const areAllSelected = selectedItems.size === orderList?.length;

  const handleSelectionChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(id)) {
        newSelectedItems.delete(id);
      } else {
        newSelectedItems.add(id);
      }
      return newSelectedItems;
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.size === orderList.length) {
      setSelectedItems(new Set());
    } else {
      setSelectedItems(new Set(orderList.map((item) => item?.id)));
    }
  };

  useEffect(() => {
    if (AccessToken) {
      callApi("history/order").executeDispatch();
    }
  }, [AccessToken]);

  useEffect(() => {
    console.log(selectedItems, "selectedItems");
  }, [selectedItems]);

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}
      <AuthHeader title={getLanguageFile.my_orders} backkey={true} />

      <div
        id="body"
        className="auth bottombutton"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <section className="container p-lr-16 m-b-16">
          <div className="flex m-b-8">
            <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-normal-hover">
              <button
                onClick={() => navigate("/my-orders-to-ship")}
                className="text-500 fs-12 font-medium p-all-8"
              >
                {getLanguageFile.to_ship}
              </button>
            </div>

            <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-light-hover">
              <button
                onClick={() => navigate("/my-orders-shipping")}
                className="text-500 fs-12 font-medium p-all-8"
              >
                {getLanguageFile.shipping}
              </button>
            </div>

            <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-light-hover">
              <button
                onClick={() => navigate("/my-orders-received")}
                className="text-500 fs-12 font-medium p-all-8"
              >
                {getLanguageFile.received}
              </button>
            </div>
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
          <div className="flex items-center justify-between">
            <span className="flex items-center fs-16 font-bold text-500">
              <ArrowLeftIcon className="flex w-[15px] h-[15px] m-all-5 justify-center items-center shrink-0 font-bold text-500" />
              To ship list ({orderList?.length} items)
            </span>
          </div>
        </section>

        <section className="container p-lr-16 m-b-24">
          <div className="flex items-center">
            <input
              id="select-all-checkbox"
              type="checkbox"
              value=""
              className={`w-4 h-4 m-r-10 appearance-none
                border-[3px] border-white shadow-radio-button
                ${!areAllSelected && "hover:shadow-hover-radio-button"}
                checked:shadow-active-radio-button rounded-full checked:bg-blue-600 focus:outline-none`}
              onChange={handleSelectAll}
              checked={areAllSelected}
            />
            <label htmlFor="default-radio-1" className="fs-12 text-gray-900">
              {getLanguageFile.allitem}
            </label>
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
          <div className="flex flex-col">
            {orderList?.map((item, key) => (
              <div key={key}>
                <div className="flex relative">
                  <input
                    type="checkbox"
                    value=""
                    name={`default-checkbox-${item?.id}`}
                    className={`flex-none w-4 h-4 m-r-10 my-auto appearance-none
                        border-[3px] border-white shadow-radio-button
                        ${
                          !selectedItems.has(item?.id) &&
                          "hover:shadow-hover-radio-button"
                        }
                        checked:shadow-active-radio-button rounded-full checked:bg-blue-600 focus:outline-none
                      `}
                    onChange={() => handleSelectionChange(item?.id)}
                    checked={selectedItems.has(item?.id)}
                  />

                  <div href="product-detail" className="w-1/2 relative">
                    <img
                      src={
                        item?.product?.feature_image
                          ? item?.product?.feature_image
                          : LuckydrawItem1
                      }
                      alt="Slide 1"
                      className=""
                    />

                    <div className={`absolute top-2 left-1 flex items-center justify-between ${item?.type == "prize" ? 'bg-red-500' : 'bg-gold-500'} text-white font-semibold rounded-full p-lr-8 m-l-4`}>
                      <span className="text-white fs-8 font-medium">
                      {item?.type}
                      </span>
                    </div>
                  </div>

                  <div className="flex-grow w-1/2 p-lr-8 p-tb-16">
                    <div className="fs-11 font-bold overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                      {item?.product?.name}
                    </div>

                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                      {item?.product?.category}
                    </div>

                    {/* {item?.type && (
                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                        Type: {item?.type}
                      </div>
                    )} */}

                    {/* {item?.status && (
                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                        Status:{" "}
                        {item?.status == "approved" ? (
                          <span
                            style={{ color: "green" }}
                            className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                          >
                            On the way
                          </span>
                        ) : item?.status == "pending" ? (
                          <span
                            style={{ color: "orange" }}
                            className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                          >
                            Processing
                          </span>
                        ) : (
                          item?.status
                        )}
                      </div>
                    )} */}

                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                      {getLanguageFile.quantity}
                      {": "}
                      {item?.qty}
                    </div>
                    <span className="flex items-center leading-normal">
                      <span className="fs-11 font-bold me-1">MMK</span>
                      <span className="fs-16 font-extrabold leading-[16px]">
                        {item?.product?.actual_price}
                      </span>
                    </span>
                    {/* {item?.product?.off_price > 0 && (
                      <span className="flex items-center text-300 leading-normal">
                        <span className="fs-11 me-1">was</span>
                        <span className="fs-11">MMK</span>
                        <span className="fs-11 line-through leading-[16px] me-1">
                          {item?.product?.actual_price}
                        </span>
                        <span className="fs-11 font-medium text-500">
                        60% off
                      </span>
                      </span>
                    )} */}

                    {/* <div className="m-b-16">
                      <span className="fs-11 text-300 leading-normal me-1">
                        {getLanguageFile.shipping}
                      </span>

                      <span className="fs-11 text-red-500 leading-[16px]">
                        MMK 5,000
                      </span>
                    </div> */}

                    {/* <div className="flex m-b-8">
                      <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-medium">
                          White
                        </span>
                      </div>

                      <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-medium">
                          Small
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="border-t-2 border-Light/active mb-4"></div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="w-100 p-lr-16 max-w-[393px] bottom-0 bg-white shadow-md-top">
        <div className="grid grid-cols-1 gap-2">
          <button
            onClick={() =>
              navigate("/confirm-shipping", {
                state: { selectedItems: selectedItems },
              })
            }
            className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
          >
            {getLanguageFile.ship_the_orders}
          </button>
        </div>
      </div>
    </>
  );
}
